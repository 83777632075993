import React, {Component} from 'react';

class Featured extends Component {

	render(){
		return(
				<section className="page-section" id="press" style={{ backgroundColor: "#f2f2f2" }}>
  
    <div className="container">
      
          <div dangerouslySetInnerHTML={{__html: localStorage.getItem('featured')}} />
      
    </div>
    <br /><br />
  </section>
			)
	}
}

export default Featured;