import React, { Component } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";

class LoadingLastOrder extends Component {



  render(){
    
    return(
<section id="page" style={{ backgroundColor: "#ffffff" }}>
    <HelmetProvider>
    <Helmet>
        <title>My Order</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content="My Order" />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="My Order"/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
      </Helmet>
    </HelmetProvider>
    

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 mx-auto">
            <div className="row" style={{ paddingBottom: "0px" }}>
              <div className="col-lg-12 text-left">
                <div style={{ height: "77px" }}></div>
                <div className="card mb-8 shadow">
                <div className="card-header bg-dark text-white pb-1">
                            <h4><i className="fas fa-file-signature"></i> My Order</h4>
                            
                </div>
                  <div className="card-body" style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "15px" }}>
                    
                  






  <div className="bd-callout bd-callout-theme shadow-sm rounded" style={{
    marginTop: '15px',
    marginBottom: '15px'
   }} >
    <div className="ph-col-12 mb-2">
                            <div className="ph-row">
                                <div className="ph-col-2 big"></div>
                                <div className="ph-col-10 empty"></div>
                            </div>
                        </div>
   <div className="card mb-2">

   <div className="card-body">
   <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          
                        </div>
                      </div>
  </div>
  </div></div>         
  </div>






  
  
  

</div>
</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "60px"}}></div>  
        </div>
      </section>
      )
  }
}

export default LoadingLastOrder;