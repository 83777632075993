import React, {Component} from 'react';
import Services from '../Services/Services';
import Header from '../Header/Header';
import Featured from '../Featured/Featured';
import Category from '../Category/Categories';

class Home extends Component {

	constructor() {
    
    	super()

    	if (localStorage.getItem('sessionId') === null) {
      		window.location.reload(false);
    	}

    	this.state = {
      		siteContent: JSON.parse(localStorage.getItem('siteContent')),
    	}
    
    
  	}
	
  	

	render(){

		const contentHeader = this.state.siteContent.indexOf("header") > -1;
		const contentService = this.state.siteContent.indexOf("service") > -1;
		const contentFeature = this.state.siteContent.indexOf("feature") > -1;

		
		return(
				<div>
					{(() => {
						if (contentHeader) {
							return (
								<Header />
							)
						}
					})()}

					{(() => {
						if (contentService) {
							return (
								<Services />
							)
						}
					})()}

					{(() => {
						if (contentFeature) {
							return (
								<Featured />
							)
						}
					})()}


					<Category />
				</div>
			)
	}
}

export default Home;