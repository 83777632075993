import React, { Component } from 'react';


class LoadingCategory extends Component {



	render(){
    
		return(
<section id="tour" style={{backgroundColor: "#ffffff"}}>


  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 mx-auto">
        <div className="row" style={{paddingBottom: "0px"}}>
          <div className="col-lg-12 text-center">
            <div style={{height: "70px"}}></div>

            
            <div className="ph-item border-0 pt-0 pb-0">
            <div className="ph-col-12 mb-0">
                <div className="ph-row">
                    <div className="ph-col-4 empty"></div>
                    <div className="ph-col-4 big"></div>
                    <div className="ph-col-4 empty"></div>
                </div>

            </div>
            <hr className="hr-theme" style={{
              marginTop: "20px"
            }} />
            </div>
            

            
            
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "0px", minHeight: "300px"}}>
          <div className="col-lg-12 text-center">
          
            <div id="placeholder2">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="ph-item">
                      <div className="ph-col-12">
                          <div className="ph-picture"></div>
                          <div className="ph-row">
                            <div className="ph-col-12 big"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-4 empty big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="ph-item">
                      <div className="ph-col-12">
                          <div className="ph-picture"></div>
                          <div className="ph-row">
                            <div className="ph-col-12 big"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-4 empty big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="ph-item">
                      <div className="ph-col-12">
                          <div className="ph-picture"></div>
                          <div className="ph-row">
                            <div className="ph-col-12 big"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-8"></div>
                            <div className="ph-col-4 empty"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-4"></div>
                            <div className="ph-col-8 empty"></div>
                            <div className="ph-col-12 empty"></div>
                            <div className="ph-col-6 big"></div>
                            <div className="ph-col-4 empty big"></div>
                            <div className="ph-col-2 big"></div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>

      </div>
    </div>
  </div>

</section>
			)
	}
}

export default LoadingCategory;