import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {
  

  render(){
    
    return (

<footer className="py-5" style={{backgroundColor: '#f2f2f2' }}>
<div className="container">
    <div className="row">
		<div className="row col-md-12">
            	<div className="col-md-4 first-column mb-4">
                	
                    <div className="m-0 text-left text-dark">
						
                        <div className="mb-2 mt-2">
                        <span dangerouslySetInnerHTML={ {__html: localStorage.getItem('footerWhatsappTitle')} } />
                        <br />
                        Your “3AM friend” is here!
						<br />
                        <a target="_blank" rel="noreferrer noopener" className="no-decoration" href={"https://wa.me/"+ localStorage.getItem('footerWhatsapp')}>
                            <img className="mb-2 img-fluid" src={ localStorage.getItem('assets') +'/img/footer/whatsapp-button.png'} alt="" />
                        </a>
                        </div>

                        

                        <div className="mb-2 mt-2">
                            <div dangerouslySetInnerHTML={ {__html: localStorage.getItem('footerCompany')} } />
                        </div>

                    </div>

                </div>

                <div className="col-md-4 second-column mb-4">
                    
                    <div className="m-0 text-left text-dark">

                    <div className="mb-2 mt-2">
                        <span dangerouslySetInnerHTML={ {__html: localStorage.getItem('footerUsefullLinksTitle')} } />
                        <br />
                        {JSON.parse(localStorage.getItem('footerUsefullLinks')).map((usefullLink,usefullLink_index) => {
                            
                            if(usefullLink.type==="outsite")
                            {
                                return (
                                    <span key={usefullLink_index}>
                                        <Link target="_blank" rel="noreferrer noopener" to={{ pathname: usefullLink.link }} className="text-theme" style={{marginTop: '10px'}}><span dangerouslySetInnerHTML={ {__html: usefullLink.title} } /></Link>
                                        <br />
                                    </span>
                                    )
                            }
                            else
                            {
                                return (
                                    <span key={usefullLink_index}>
                                    <Link to={usefullLink.link} className="text-theme" style={{marginTop: '10px'}}><span dangerouslySetInnerHTML={ {__html: usefullLink.title} } /></Link>
                                    <br />
                                    </span>
                                )
                            }

                        })}
                    </div>
                    <div className="mb-2 mt-2">
                        <span dangerouslySetInnerHTML={ {__html: localStorage.getItem('footerPrivacytermsTitle')} } />
                        <br />
                        {JSON.parse(localStorage.getItem('footerPrivacyterms')).map((privacyterm,privacyterm_index) => {
                            return (
                                    <span key={privacyterm_index}>
                                    <Link to={privacyterm.link} className="text-theme" style={{marginTop: '10px'}}><span dangerouslySetInnerHTML={ {__html: privacyterm.title} } /></Link>
                                    <br />
                                    </span>
                                )
                        })}
                    </div>

                    


                    </div>

                </div>
                <div className="col-md-4 second-column mb-4">
                    
                    <div className="m-0 text-left text-dark">
                    
                    

                    <div className="mb-2 mt-2">
                        <span dangerouslySetInnerHTML={ {__html: localStorage.getItem('footerPartnersTitle')} } />
                        <br />
                        {JSON.parse(localStorage.getItem('footerPartners')).map((partner,partner_index) => {
                            return (
                                    <span key={partner_index} dangerouslySetInnerHTML={ {__html: partner} } />
                                )
                        })}
                    </div>
                    

                    <div className="mb-2 mt-2">
                        <span dangerouslySetInnerHTML={ {__html: localStorage.getItem('footerPaymentChannelsTitle')} } />
                        <br />
                        {JSON.parse(localStorage.getItem('footerPaymentChannels')).map((paymentChannel,paymentChannel_index) => {
                            return (
                                    <span key={paymentChannel_index} dangerouslySetInnerHTML={ {__html: paymentChannel} } />
                                )
                        })}
                    </div>   
                    

                    </div>

                </div>
        </div>
        <div className="col-md-12 footer">
            <div style={{height: '20px'}} />
                <div dangerouslySetInnerHTML={ {__html: localStorage.getItem('footerTitle')} } />
            <small> version : {process.env.REACT_APP_VERSION} </small>
            <div style={{height: '70px'}} />
        </div>
        
    </div>
</div>

</footer>

        )
  }
}

export default Footer;
