import React, {Component} from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";


class NotFound extends Component {
  
  
  componentDidMount() {
    window.scrollTo(0, 1);
    
  }

  componentDidUpdate(){
   window.scrollTo(0, 1);
  }
  
  render(){
    
  return (
    <section id="page" style={{ backgroundColor: "#ffffff" }}>
    <HelmetProvider>
    <Helmet>
        <title>Lost in Jogja</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Lost in Jogja" />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Lost in Jogja"/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
    </Helmet>
    </HelmetProvider>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 mx-auto">
            <div className="row" style={{ paddingBottom: "0px" }}>
              <div className="col-lg-12 text-left">
                <div style={{ height: "77px" }}></div>
                <div className="card mb-8 shadow p-2">
                  <div className="card-body" style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "15px" }}>
                    <div className="text-right">
          
                    </div>
              
                    
          
                    <div className="row col-md-4  mx-auto d-flex justify-content-center">
                      <div className="textwidget" style={{ minHeight: "450px" }}>
                        <div style={{ height: "100px" }}></div>
                        <div className="d-flex justify-content-center">
                       
                               
                                  <h1> 404 </h1>
                               
                               
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                         <h2>LOST IN JOGJA</h2>
                        </div>
                                
                          <div style={{ height: "50px" }}></div>
                        </div>
                      </div>
                    </div>

                  </div>
                     
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "60px"}}></div>  
        </div>
      </section>
    	)
  }
}

export default NotFound;