import React, {Component} from 'react';

class TourGuide extends Component {

	render(){
		return(
				<section id="guide" style={{ backgroundColor: "#ffffff"}}>
          <div className="container">

              <div className="row">
                  <div className="col-lg-8 col-md-10 mx-auto">
                      <div className="row">
                          <div className="col-lg-12 text-center">
                              <h3 className="section-heading" style={{ marginTop: "50px"}}><span dangerouslySetInnerHTML={ {__html: localStorage.getItem('tourGuide_title') } } /></h3>
                              <p><span dangerouslySetInnerHTML={ {__html: localStorage.getItem('tourGuide_description') } } /></p>
                              <hr style={{ maxWidth: "50px", borderColor: "#e2433b", borderWidth: "3px"}} />
                          </div>
                      </div>
                  </div>
              </div>

              <div className="row justify-content-center mt-4"> 
                  <div className="row col-12">

                      {JSON.parse(localStorage.getItem('tourGuides')).map((tourGuide,tourGuide_index) => {
                            
                            return (
                                    <div key={tourGuide_index} className="d-flex flex-wrap justify-content-center col-lg-3 col-md-6 mx-auto">
                                        <div className="team-member" style={{ marginBottom: "5px", marginLeft: "100px", marginRight: "100px"}}>
                                            <img alt="Tour Guide | Vertikal Trip" className="mx-auto rounded-circle" src={tourGuide.image} />
                                            <h5 className="mt-2">{tourGuide.name}</h5>
                                            <p className="text-muted">{tourGuide.description}</p>
                                            <br /><br />
                                        </div>
                                    </div>
                                )

                        })}

                      

                      

                  </div>
              </div>

              

          </div>
        </section>
			)
	}
}

export default TourGuide;