import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingCheckout from './LoadingCheckout';
import { getDatabase, ref, onValue} from "firebase/database";
import EmptyCart from './EmptyCart';

class Checkout extends Component {
  
  constructor() {
    super()

    if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }

    this.state = {
      shoppingcarts: [],
      payment: '',
      payment_default: '',
      payment_array: [],
      payment_information: '',
      agreement: '',
      redirect: '',
      loadInputFormat: ''
    }
    
    this.formCheckout = this.formCheckout.bind(this);
    this.formPromoCode = this.formPromoCode.bind(this);
    this.termCheck = this.termCheck.bind(this);
    this.radioPayment = this.radioPayment.bind(this);
    
    const openAppRoute = (redirectUrl) => {
      this.unmountScript();
      this.setState({
        redirect: redirectUrl
      });
    };

    window.startListenerPayment = this.startListenerPayment;
    window.stopListenerPayment = this.stopListenerPayment;
    window.clearTrackingCode = this.clearTrackingCode;
    window.openAppRoute = openAppRoute;
    
    
    this._isMounted = false;
  }

  
  radioPayment(event){
    this.setState({
          payment: event.target.value
        });
    this.submitText(event.target.value);
  }

  clearTrackingCode()
  {
    localStorage.setItem('trackingCode', "");
  }

  scriptLoader(provider){
      try
      {
        document.getElementById("scriptLoader").remove();
      }
      catch(error)
      {

      }
      const scriptLoader = document.createElement("script");
      scriptLoader.src = process.env.REACT_APP_API_URL +'/payment/'+ provider +'/jscript/'+ localStorage.getItem('sessionId');
      scriptLoader.async = false;
      scriptLoader.setAttribute("id",'scriptLoader');
      document.body.appendChild(scriptLoader);
  }


  startListenerShoppingcart(reference_id)
  {
    const db = getDatabase();
    const starCountRef = ref(db, 'shoppingcart/'+ reference_id);

    let listen = onValue(starCountRef, (snapshot) => {
      if (snapshot.exists()) {

        if(this._isMounted)
        {
          this.setState({
            shoppingcarts: snapshot.val().shoppingcarts,
            payment_array: snapshot.val().payment_enable.split(','),
            payment_default: snapshot.val().payment_default,
            payment_information: snapshot.val().payment_information,
            agreement: snapshot.val().agreement
          });
          
        }

        if(this.state.payment==='')
        {
          this.setState({
            payment: this.state.payment_default
          });
        }

        if(this.state.loadInputFormat==='')
        {
          try
          {
            window.loadInputFormat();
          }
          catch
          {
            
          }
          

          this.setState({
            loadInputFormat: 'loaded'
          });
        }
        

        this.submitText(this.state.payment);
        
        
      }
      else {
        listen();
      }

        

    });

  }

  stopListenerShoppingcart(reference_id)
  {
    const db = getDatabase();
    const starCountRef = ref(db, 'shoppingcart/'+ reference_id);
    let listen = onValue(starCountRef, (snapshot) => {
    });
    listen();
  }

  startListenerPayment(reference_id)
  {
    const db = getDatabase();
    const starCountRef = ref(db, 'payment/'+ reference_id);
    onValue(starCountRef, (snapshot) => {

      if (snapshot.exists()) {
        if(snapshot.val().status==="CONFIRMED")
        {
            window.afterCheckout(snapshot.val().redirect_url);
        }
        else if(snapshot.val().status==="FAILED")
        {
            this.stopListenerPayment(reference_id);
            window.failedpaymentEwallet(snapshot.val().ewallet);
        }
        else
        {

        }
      }

    });
  }

  stopListenerPayment(reference_id)
  {
    const db = getDatabase();
    const starCountRef = ref(db, 'payment/'+ reference_id);
    let listen = onValue(starCountRef, (snapshot) => {
    });
    listen();
  }

  fetchApi() {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ sessionId: localStorage.getItem('sessionId') })
      };
      fetch(process.env.REACT_APP_API_URL+'/shoppingcart', requestOptions)
      .then(response => response.json())
      .then((json) => {

        if(this._isMounted)
        {
          const scriptCheckout = document.createElement("script");
          scriptCheckout.src = process.env.REACT_APP_API_URL +'/checkout/jscript';
          scriptCheckout.async = false;
          scriptCheckout.setAttribute("id",'scriptCheckout');
          document.head.appendChild(scriptCheckout);

        }

        this.stopListenerShoppingcart(localStorage.getItem('sessionId'));
        this.startListenerShoppingcart(localStorage.getItem('sessionId'));
        




      }).catch((e) => {

      });
      
      
  }

  

  enableButtonTrash() {
      this.state.shoppingcarts.forEach((shoppingcart) => {
          if('products' in shoppingcart)
          {
              shoppingcart.products.forEach((product) => {
              try {
                  document.getElementById(product.booking_id).disabled = false;
                  document.getElementById(product.booking_id).innerHTML = '<i class="fas fa-times"></i>';
              } catch (error) {
  
              }
              })
          }
          
      })
  }

  removeButtonRender(booking_id) {
        return (
          <button id={booking_id} className="btn btn-small btn-light" onClick={() => this.removeProduct(booking_id)}><i className="fas fa-times"></i></button>
        )
  }

  removeProduct(id) {
    if(document.getElementById(id).disabled!==true)
    {
      document.getElementById(id).disabled = true;
      document.getElementById(id).innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
      
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ bookingId: id, sessionId: localStorage.getItem('sessionId') })
      };
      fetch(process.env.REACT_APP_API_URL+'/activity/remove', requestOptions)
      .then((response) => {
        response.json();
      } )
      .then((json) => {
            
            this.enableButtonTrash();
            
       })
    }

  }

  formCheckout(event) {
    
    window.showAlert('','hide');
    window.submitDisabled();
    
    var obj = {};
    var obj2 = {};
    var formQuestionId = [];
    obj['sessionId'] = localStorage.getItem('sessionId');
    this.state.shoppingcarts.forEach((shoppingcart,index) => {
      
      shoppingcart.mainQuestions.forEach((mainQuestion,index) => {
          obj2[mainQuestion.question_id] = document.getElementById(mainQuestion.question_id).value;
          formQuestionId.push(mainQuestion.question_id);
      });

      shoppingcart.productQuestions.forEach((productQuestion,index) => {
        productQuestion.questions.forEach((question,index) => {
          obj2[question.question_id] = document.getElementById(question.question_id).value;
          formQuestionId.push(question.question_id);
        });
      });

      shoppingcart.productQuestions.forEach((productQuestion,index) => {
        Object.entries(productQuestion.question_participants).forEach(([key, question_participant]) => {
          question_participant.forEach((question,index) => {
            
            obj2[question.question_id] = document.getElementById(question.question_id).value;
            formQuestionId.push(question.question_id);
          });
        });
      });

    })

   
    try {
      obj2['phoneNumber'] = document.querySelector("[name='phone_full']").value;
    } catch (error) {
  
    }
    
    obj['questions'] = obj2;

    window.clearFormAlert(formQuestionId);

    this.disableFormCheckout();

    obj['payment'] = this.state.payment;

    obj['trackingCode'] = localStorage.getItem('trackingCode');

    

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(obj)
      };
    
    fetch(process.env.REACT_APP_API_URL+'/payment/checkout', requestOptions)
      .then(response => response.json())
      .then((json) => {

            if(json.message==="success")
            {
                this.scriptLoader(json.payment);
            }
            else
            {
              window.formAlert(json);
              window.submitEnabled();
              this.submitText(this.state.payment);
              this.enableFormCheckout();
              window.showAlert(json.message,'show');
            }
            
       })

    event.preventDefault();
  }

  enableFormCheckout()
  {
    this.state.shoppingcarts.forEach((shoppingcart,index) => {

      shoppingcart.products.forEach((product,index) => {
          document.getElementById(product.booking_id).disabled = false;
          document.getElementById(product.booking_id).style.visibility = "visible";
      });

      shoppingcart.mainQuestions.forEach((mainQuestion,index) => {
          document.getElementById(mainQuestion.question_id).disabled = false;
          //document.getElementById(mainQuestion.question_id).style.border = "1px solid #ced4da";
          //document.getElementById(mainQuestion.question_id).style.backgroundColor = "#FFFFFF";
      });

      shoppingcart.productQuestions.forEach((productQuestion,index) => {
        productQuestion.questions.forEach((question,index) => {
          document.getElementById(question.question_id).disabled = false;
          //document.getElementById(question.question_id).style.border = "1px solid #ced4da";
          //document.getElementById(question.question_id).style.backgroundColor = "#FFFFFF";
        });
      });

      shoppingcart.productQuestions.forEach((productQuestion,index) => {
        Object.entries(productQuestion.question_participants).forEach(([key, question_participant]) => {
          question_participant.forEach((question,index) => {
            document.getElementById(question.question_id).disabled = false;
            //document.getElementById(question.question_id).style.border = "1px solid #ced4da";
            //document.getElementById(question.question_id).style.backgroundColor = "#FFFFFF";
          });
        });
      });

    });
    
    try {
      document.getElementById("promocode").disabled = false;
    } catch (error) {
  
    }

    document.getElementById("apply").disabled = false;

    this.state.shoppingcarts.forEach((shoppingcart,index) => {
        this.state.payment_array.forEach((payment)=>{
          document.getElementById("payment_"+ payment).disabled = false;
        })
    });
    document.getElementById("term").disabled = false;

    
  }

  disableFormCheckout()
  {
    this.state.shoppingcarts.forEach((shoppingcart,index) => {

      shoppingcart.products.forEach((product,index) => {
          document.getElementById(product.booking_id).disabled = true;
          document.getElementById(product.booking_id).style.visibility = "hidden";
      });

      shoppingcart.mainQuestions.forEach((mainQuestion,index) => {
          document.getElementById(mainQuestion.question_id).disabled = true;
          //document.getElementById(mainQuestion.question_id).style.border = "0px none";
          //document.getElementById(mainQuestion.question_id).style.backgroundColor = "#FFFFFF";
      });

      shoppingcart.productQuestions.forEach((productQuestion,index) => {
        productQuestion.questions.forEach((question,index) => {
          document.getElementById(question.question_id).disabled = true;
          //document.getElementById(question.question_id).style.border = "0px none";
          //document.getElementById(question.question_id).style.backgroundColor = "#FFFFFF";
        });
      });

      shoppingcart.productQuestions.forEach((productQuestion,index) => {
        Object.entries(productQuestion.question_participants).forEach(([key, question_participant]) => {
          question_participant.forEach((question,index) => {
            document.getElementById(question.question_id).disabled = true;
            //document.getElementById(question.question_id).style.border = "0px none";
            //document.getElementById(question.question_id).style.backgroundColor = "#FFFFFF";
          });
        });
      });

    });
    
    try {
      document.getElementById("promocode").disabled = true;
    } catch (error) {
  
    }

    document.getElementById("apply").disabled = true;

    this.state.shoppingcarts.forEach((shoppingcart,index) => {
        this.state.payment_array.forEach((payment)=>{
          document.getElementById("payment_"+ payment).disabled = true;
        })
    });
    document.getElementById("term").disabled = true;

    
  }

  termCheck(event) {
      if(document.getElementById("term").checked)
      {
        document.getElementById("submitCheckout").disabled = false;
      }
      else
      {
        document.getElementById("submitCheckout").disabled = true;
      }
  }

  submitText(payment,currency="") {
        this.state.shoppingcarts.forEach((shoppingcart,index) => {
          try
              {
                document.getElementById("submitText").innerHTML = "Pay "+ shoppingcart[payment+'_currency'] +" "+ shoppingcart[payment+'_total'];
                document.getElementById("payment_"+ payment).checked = true;
              }
              catch (error)
              {

              }
        });
  }



  formPromoCode(event)
  {
    document.getElementById("promocode").disabled = true;
    document.getElementById("apply").disabled = true;
    document.getElementById("apply").innerHTML = '<i class="fa fa-spinner fa-spin"></i>';

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ promocode: document.getElementById("promocode").value, sessionId: localStorage.getItem('sessionId') })
      };
      fetch(process.env.REACT_APP_API_URL+'/promocode', requestOptions)
      .then(response => response.json())
      .then((json) => {
            if(json.message!=="success")
            {
                document.getElementById("promocode").disabled = false;
                document.getElementById("apply").disabled = false;
                document.getElementById("apply").innerHTML = 'Apply';
            }
            
            
       })

    

    event.preventDefault();
  }

  removePromoCode()
  {
    if(document.getElementById("apply").disabled!==true) {
      document.getElementById("apply").disabled = true;
      document.getElementById("apply").innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ sessionId: localStorage.getItem('sessionId') })
      };
      fetch(process.env.REACT_APP_API_URL+'/promocode/remove', requestOptions)
      .then(response => response.json())
      .then((json) => {
           
            
       })
    } 
    
    
  }

  unmountScript()
  {
    
    try
    {
      document.getElementById("scriptLoader").remove();
    }
    catch(error)
    {

    }

    try
    {
      document.getElementById("scriptCheckout").remove();
    }
    catch(error)
    {

    }

    
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchApi();
    window.scrollTo(0, 1);

  }

  componentDidUpdate(){
   if(this.state.payment==="")
   {
      window.scrollTo(0, 1);
   }
  }

  componentWillUnmount()
  {
    this._isMounted = false;
    this.stopListenerShoppingcart(localStorage.getItem('sessionId'));
    this.unmountScript();
  }

  render(){

  if(this.state.redirect!==""){
    let redirectUrl = this.state.redirect;
    return <Redirect to={redirectUrl} />
  }

  if(this.state.shoppingcarts.length===0)
  {
      return <LoadingCheckout />
  }



  return (
    <section id="booking" style={{ backgroundColor: "#ffffff" }}>
    <HelmetProvider>
    <Helmet>
        <title>Shopping Cart</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Shopping Cart" />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Shopping Cart"/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
    </Helmet>
    </HelmetProvider>

      {this.state.shoppingcarts.map((shoppingcart,shoppingcart_index) => {
        
        if('products' in shoppingcart)
        {

        }
        else
        {
            return (
                <EmptyCart key="shoppingcart_index" />
            );
        }
        

        let dynamicProps = [];
        this.state.payment_array.forEach((payment)=>{
            let payment_rate = payment+'Rate';
            dynamicProps[payment_rate] = (
                <small><div className="form-text text-muted" dangerouslySetInnerHTML={ {__html: ''+ shoppingcart[payment+'_rate']} } /></small>
            );

        });


        


        let voucherBox = ('');
        
        if(shoppingcart.promo_code===undefined)
        {
            shoppingcart.promo_code = null;
        }

        if(shoppingcart.promo_code!==null) {
          voucherBox = (
            <div className="card shadow mt-4">
              <div className="card-body">
                <div id="alert-promocode"></div>
                <div className="row mb-2">
                  <div className="col-6 my-auto">
                    <strong>Promo code : {shoppingcart.promo_code}</strong>
                  </div>
                  <div className="col-6 my-auto text-right">
                    <button id="apply" type="button" onClick={this.removePromoCode.bind(this)} className="btn btn-light"><i className="fas fa-times"></i> Remove code</button>
                  </div>
                </div>  
              </div>
            </div>
          ); 
        }
        else
        {
          voucherBox = (
            <div className="card shadow mt-3">
              <div className="card-body">
                <form onSubmit={this.formPromoCode}>
                  <div className="form-row align-items-center">
                    <div className="col-auto">
                      <input id="promocode" type="text" defaultValue="" autoComplete="off" className="form-control" placeholder="Promo code" required />
                    </div>
                    <div className="col-auto">
                      <button id="apply" type="submit" className="btn btn-secondary ">Apply</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          );  
        }

        let discountBox = ('')
        if(parseInt(shoppingcart.discount)>0) {
          discountBox = (
            <div className="row mb-2">
              <div className="col-8">
                <span style={{ fontSize: "16px" }}>Discount</span>
              </div>
              <div className="col-4 text-right">
                <span style={{ fontSize: "16px" }}>{shoppingcart.discount}</span>
              </div>
            </div>
          )
        }

        let dueOnArrivalBox = ('')
        if(parseInt(shoppingcart.due_on_arrival)>0) {
          dueOnArrivalBox = (
            <div className="card-body pt-0 pb-0">
              <hr className="mt-4" /> 
              <div className="row mb-2 mt-0">
                <div className="col-8">
                  <b style={{ fontSize: "16px" }}>Due now ({ shoppingcart.currency })</b>
                </div>
                <div className="col-4 text-right">
                  <b style={{ fontSize: "16px" }}>{ shoppingcart.due_now }</b>
                </div>
              </div>
              <div className="row mb-4 mt-0">
                <div className="col-8">
                  <span style={{ fontSize: "16px" }}>Due on arrival  ({ shoppingcart.currency })</span>
                </div>
                <div className="col-4 text-right">
                  <span style={{ fontSize: "16px" }}>{ shoppingcart.due_on_arrival }</span>
                </div>
              </div>
              
            </div>
          )
        }

      

      return (
        <div className="container" key={shoppingcart_index}>
          <div className="row">
            <div className="col-lg-12 col-md-12 mx-auto">
              <div className="row" style={{ paddingBottom: "0px" }}>
                <div className="col-lg-12 text-left">
                  <div style={{ height: "56px" }}></div>
                    <div className="row mb-2">  
                      <div className="col-lg-6 col-lg-auto mb-6 mt-4">

                        <div className="card shadow">
                          <div className="card-header bg-dark text-white pb-1">
                            <h4><i className="fas fa-shopping-cart"></i> Order Summary</h4>
                          </div>
                
                          <div id="productSummary" className="card-body">
                          
                          


                          { shoppingcart.products.map((product,product_index) => {
                  
                            let imageBox = ('')
                            if(product.image!=="")
                            {
                              imageBox = (
                                <div style={{ width: "60px", height: "60px",  position: "relative", paddingTop: "4px" }}>
                                  <img src={product.image} alt={product.title} className="rounded" height="60"  />
                                </div>
                              )
                            }

                            return (
                              <div className="no-gutters" id={ "bookingId-"+ product.booking_id } key={product_index}>
                                <div className="row mb-2">
                                  <div className="col-8 pl-4">
                                    <b>{ product.title }</b>
                                  </div>
                                  <div className="col-4 text-right">
                                    <div dangerouslySetInnerHTML={ {__html: product.product_total} } />
                                  </div>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-10 row">
                                    <div className="ml-4 mb-2">
                                      {imageBox}
                                    </div>
                                    <div className="col-8 product-detail pl-4">
                                      {product.date}
                                      <br />
                                      {product.rate}
                                      <br />
                                      <div dangerouslySetInnerHTML={ {__html: product.product_detail} } />
                                    </div>
                                  </div>
                                  <div className="col text-right">
                                    {this.removeButtonRender(product.booking_id)}
                                  </div>
                                  <div className="ml-4 product-detail mr-4 mt-2" dangerouslySetInnerHTML={ {__html: product.note} } />
                                </div>


                                {(() => {
                                  if('pickups' in product) {
                                    if(product.pickups.length!==0) {
                                    const pickupsBox = [];
                                    product.pickups.forEach((pickup) => {
                                      pickupsBox.push(
                                        <div className="row mb-2">
                                          <div className="col-8">
                                            <strong style={{ fontSize: "12px" }}>{pickup.title}</strong>
                                            <br />
                                            <span style={{ fontSize: "12px" }}>{pickup.unit_price}</span>
                                          </div>
                                          <div className="col-4 text-right">
                                            <div dangerouslySetInnerHTML={ {__html: pickup.price} } />
                                          </div>
                                        </div>
                                      )
                                    })

                                    return (
                                      <div className="card mb-4">
                                        <div className="card-body">
                                          {pickupsBox}
                                        </div>
                                      </div>
                                    )
                                    }
                                  }
                                })()}
                                


                                {(() => {
                                  if('extras' in product)
                                  {
                                    if(product.extras.length!==0) {
                                    const extrasBox = [];
                                    product.extras.forEach((extra) => {

                                      extrasBox.push(
                                        <div className="row mb-2">
                                          <div className="col-8">
                                            <strong style={{ fontSize: "12px" }}>{extra.title}</strong>
                                            <br />
                                            <span style={{ fontSize: "12px" }}><div dangerouslySetInnerHTML={ {__html: extra.unit_price} } /></span>
                                          </div>
                                          <div className="col-4 text-right">
                                            <div dangerouslySetInnerHTML={ {__html: extra.price} } />
                                          </div>
                                        </div>
                                      )
                        
                                    })

                                    return (
                                      <div className="card mb-4">
                                        <div className="card-body">
                                          {extrasBox}
                                        </div>
                                      </div>
                                    )
                                  }
                                  }
                                })()}
                  
                              </div>
                            )
                  
                          })}
                          
                          </div>
                          <div className="card-body pt-0 mt-0">
                            <hr />
                            <div className="row mb-2">
                              <div className="col-8">
                                <span style={{ fontSize: "16px" }}>Items</span>
                              </div>
                              <div className="col-4 text-right">
                                <span style={{ fontSize: "16px" }}>{shoppingcart.subtotal}</span>
                              </div>
                            </div>
                            {discountBox}

                            <div className="row mb-2">
                              <div className="col-8">
                                <b style={{ fontSize: "16px" }}>Total ({shoppingcart.currency})</b>
                              </div>
                              <div className="col-4 text-right">
                                <b style={{ fontSize: "16px" }}>{shoppingcart.total}</b>
                              </div>
                            {dueOnArrivalBox}
                            </div>

                            
                            
                          </div>
                        </div>

                        {voucherBox}

                      </div>
            
                      <div className="col-lg-6 col-lg-auto mb-6 mt-4">
                        
                          <div className="card mb-8 shadow p-2">
                          
                            <div className="card-body" style={{ 
                              paddingLeft: "10px",
                              paddingRight: "10px", 
                              paddingTop: "10px", 
                              paddingBottom: "0px",
                              marginBottom: "0px",
                            }}>
                            <form onSubmit={this.formCheckout}>

                            

                              <h2>Main Contact</h2>
                              {shoppingcart.mainQuestions.map((mainQuestion,mainQuestion_index) => {

                                let inputRequired = '';
                                if(mainQuestion.required===true) {
                                  inputRequired = 'required';
                                }


                                let inputMainQuestion = '';
                                if(mainQuestion.data_format==="EMAIL_ADDRESS") {
                                  inputMainQuestion = (
                                    <input name={mainQuestion.question_id} defaultValue={mainQuestion.answer} type="email" className="form-control" id={mainQuestion.question_id} style={{ height: "47px" }} required={inputRequired} />
                                  )
                                }
                                else if(mainQuestion.data_format==="PHONE_NUMBER") {
                                  inputMainQuestion = (
                                    <div>
                                    <input name={mainQuestion.question_id} defaultValue={mainQuestion.answer} type="tel" className="form-control" id={mainQuestion.question_id} style={{ height: "47px" }} required={inputRequired} />
                                    
                                    
                                    </div>
                                  )
                                }
                                else {
                                  inputMainQuestion = (
                                    <input name={mainQuestion.question_id} defaultValue={mainQuestion.answer} type="text" className="form-control" id={mainQuestion.question_id} style={{ height: "47px" }} required={inputRequired} />
                                  )
                                }

                                
                                

                                return (
                                  <div className="form-group" key={mainQuestion_index}>
                                    <label htmlFor={mainQuestion.question_id} className={inputRequired}><strong>{mainQuestion.label}</strong></label>
                                    {inputMainQuestion}
                                  </div>
                                )

                              })}





                              {shoppingcart.productQuestions.map((productQuestion,productQuestion_index) => {

                                  
                                  let statusTitleQuestion = "false";
                                  let titleQuestion = '';

                                  if('questions' in productQuestion)
                                  {
                                    if(productQuestion.questions.length !== 0)
                                    {
                                      statusTitleQuestion = "true";
                                    }

                                  }
                                  else
                                  {
                                    productQuestion.questions = [];
                                  }
                                  
                                  if('question_participants' in productQuestion)
                                  {
                                    if(productQuestion.question_participants.length !== 0)
                                    {
                                        statusTitleQuestion = "true";
                                    }

                                  }
                                  else
                                  {
                                    productQuestion.question_participants = [];
                                  }


                                  if(statusTitleQuestion==="true")
                                  {
                                      titleQuestion = '<h2>Question for '+productQuestion.title+'<br /><small>'+productQuestion.description+'</small></h2>';
                                  }

                                  return (
                                      <div key={productQuestion_index}>
                                          <div dangerouslySetInnerHTML={ {__html: titleQuestion} } />
                                          

                                          {productQuestion.questions.map((question,question_index) => {
                                              let inputRequired = '';
                                              if(question.required===true) {
                                                  inputRequired = 'required';
                                              }

                                              let inputProductQuestion = '';
                                              if(question.data_type==="SHORT_TEXT")
                                              {
                                                  inputProductQuestion = (
                                                      <input type="text" id={question.question_id} defaultValue={question.answer} style={{ height: "47px" }} name={question.question_id} className="form-control" required={inputRequired} />
                                                  )
                                              }

                                              if(question.data_type==="LONG_TEXT")
                                              {
                                                inputProductQuestion = (
                                                    <textarea type="text" id={question.question_id} defaultValue={question.answer} style={{ height: "141px" }} name={question.question_id} className="form-control" required={inputRequired} />
                                                )
                                              }

                                              if(question.data_type==="OPTIONS")
                                              {
                                                inputProductQuestion = (
                                                    <select defaultValue={question.answer} id={question.question_id} style={{ height: "47px" }} name={question.question_id} className="form-control" required={inputRequired}>
                                                    {question.question_options.map((question_option,question_option_index) => {
                                                
                                                      return (
                                                        <option key={question_option_index} value={question_option.value}>{question_option.label}</option>
                                                      );

                                                    })}
                                                    </select>
                                                )
                                              }

                                              return (
                                                <div className="form-group" key={question_index}>
                                                  <label htmlFor={question.question_id} className={inputRequired}><strong>{question.label}</strong></label>
                                                    {inputProductQuestion}
                                                    <small className="form-text text-muted">{question.help}</small>
                                                </div>
                                              )
                                          })}

                                          {Object.entries(productQuestion.question_participants).map(([key, question_participant],question_participant_index) => {
                                              return (
                                                  <div className="card mb-4" key={question_participant_index}>
                                                  <div className="card-header">
                                                      <strong>{key}</strong>
                                                  </div>
                                                  <div className="card-body">
                                                  <div className="form-group">
                                                      {question_participant.map((question,question_index) => {

                                                          let inputRequired = '';
                                                          if(question.required===true) {
                                                              inputRequired = 'required';
                                                          }

                                                          let inputProductQuestion = '';
                                                          if(question.data_type==="SHORT_TEXT")
                                                          {
                                                              inputProductQuestion = (
                                                                  <input type="text" id={question.question_id} defaultValue={question.answer} style={{ height: "47px" }} name={question.question_id} className="form-control" required={inputRequired} />
                                                              )
                                                          }

                                                          if(question.data_type==="LONG_TEXT")
                                                          {
                                                              inputProductQuestion = (
                                                                <textarea type="text" id={question.question_id} defaultValue={question.answer} style={{ height: "141px" }} name={question.question_id} className="form-control" required={inputRequired} />
                                                              )
                                                          }

                                                          if(question.data_type==="OPTIONS")
                                                          {
                                                              inputProductQuestion = (
                                                                <select defaultValue={question.answer} id={question.question_id} style={{ height: "47px" }} name={question.question_id} className="form-control" required={inputRequired}>
                                                                  {question.question_options.map((question_option,question_option_index) => {
                                                                    return (
                                                                      <option key={question_option_index} value={question_option.value}>{question_option.label}</option>
                                                                    );
                                                                  })}
                                                                </select>
                                                              )
                                                          }

                                                          return (
                                                              <div key={question_index} className="form-group">
                                                                  <label htmlFor={question.question_id} className={inputRequired}><strong>{question.label}</strong></label>
                                                                    {inputProductQuestion}
                                                                  <small className="form-text text-muted">{question.help}</small>
                                                              </div>
                                                          )

                                                      })}
                                                  </div>
                                                  </div>
                                                  </div>
                                                )
                                          })}

                                      </div>
                                    )

                              })}





                               

                              













                              <h2 className="mb-1">Choose your payment method</h2>
                              <div className="mb-2" dangerouslySetInnerHTML={ {__html: this.state.payment_information} } />
                              <div className="form-group">
                                

                                <div id="paymentMethod">

                                      {this.state.payment_array.map((payment_array,payment_array_index) => {
                                        
                                        const payment_id = "payment_"+ payment_array;
                                        const payment_label = shoppingcart[payment_array+"_label"];
                                        const payment_rate = shoppingcart[payment_array+"_rate"];
                                        

                                        return(
                                          <div key={payment_array_index} className={`card bg-light mb-2 ${this.state.payment === payment_array ? 'border-primary border border-2': ''}`}>
                                            <div className="card-body pl-3">
                                              <div className="form-check">
                                                <input onClick={this.radioPayment.bind(this)} className="form-check-input" type="radio" name="payment_type" id={payment_id} value={payment_array}  defaultChecked={ this.state.payment === payment_array ? "checked" : null } />
                                                <label className="form-check-label ml-2" htmlFor={payment_id}>
                                                  <div dangerouslySetInnerHTML={ {__html: payment_label } } />
                                                  <div className="ml-0 mt-2">
                                                    <div dangerouslySetInnerHTML={ {__html: payment_rate } } />
                                                  </div>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })}
                                      
                                
                                </div>
                                

                                <div className="form-check mt-3">
                                  <input className="form-check-input" type="checkbox" onClick={this.termCheck.bind(this)} id="term" />
                                  
                                  <label className="form-check-label ml-1" htmlFor="term">
                                    <div dangerouslySetInnerHTML={ {__html: this.state.agreement} } />
                                  </label>

                                </div>

                              </div>

                              
                              <button id="submitCheckout" type="submit" style={{ height: "47px" }} disabled className="btn btn-lg btn-block btn-theme mt-2"><i className="fas fa-lock"></i> <strong id="submitText"></strong></button>
                              
                              <div id="info-payment"></div>

                            </form>  
                            </div>
                            

                            <div className="card-body" style={{ 
                              paddingLeft: "10px",
                              paddingRight: "10px", 
                              paddingTop: "0px", 
                              paddingBottom: "15px",
                              marginTop: "0px",
                            }}>
                            <div id="paymentContainer" />
                            <div id="alert-payment" />
                            <div id="notice" />
                            </div>

                          </div>
                        

                       

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "50px"}}></div>   
          </div>  
      )
      })}
    </section>
  )
  }
}

export default Checkout;