import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingProduct from './LoadingProduct';
import BookingCalendar from '../Booking/BookingCalendar';
import '../../assets/css/activity-time-selector.css';


class Product extends Component {
  
  constructor() {
    super()
    this.state = {
      product: [],
      json_ld: null,
      redirect: ''
    }

    if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }

    

    const openAppRoute = (urlRedirect) => {
      this.unmountScript();
      this.setState({
        redirect: urlRedirect
      });
    };
    window.openAppRoute = openAppRoute;

    const reloadJscript = () => {
      this.fetchJScript();
    };
    window.reloadJscript = reloadJscript;
  }

  fetchApi()
  {
    const {slug} = this.props.match.params;
    fetch(process.env.REACT_APP_API_URL+`/product/${slug}`)
    .then(response => response.json())
    .then((json) => {

          this.setState({
            product: json.product,
            json_ld: json.json_ld
          });

         

    })
    .catch((e) => {
        
        

    })
  }

  fetchJScript()
  {
    
    const sessionId = localStorage.getItem('sessionId');

    const scriptProduct = document.createElement("script");
    scriptProduct.src = process.env.REACT_APP_API_URL +"/product/"+ this.props.match.params.slug +"/"+sessionId+"/product_jscript";
    scriptProduct.async = true;
    scriptProduct.setAttribute("id",'scriptProduct');
    scriptProduct.onload = function(e)
    {
      const scriptBokun = document.createElement("script");
      scriptBokun.src = localStorage.getItem('assets') +"/js/App.js";
      scriptBokun.async = true;
      scriptBokun.setAttribute("id",'scriptBokun');
      scriptBokun.setAttribute("trackingCode",'scriptBokun');
      document.body.appendChild(scriptBokun);
    }
    document.body.appendChild(scriptProduct);

    
  }

  fetchScroll()
  {
      const scriptScroll = document.createElement("script");
      scriptScroll.src = localStorage.getItem('assets') +"/js/Scroll.js";
      scriptScroll.async = true;
      scriptScroll.setAttribute("id",'scriptScroll');
      document.body.appendChild(scriptScroll);
  }

  unmountScript()
  {
    try
    {
      document.getElementById("scriptBokun").remove();
    }
    catch(error)
    {

    }

    try
    {
      document.getElementById("scriptProduct").remove();
    }
    catch(error)
    {
      
    }

    try
    {
      document.getElementById("scriptScroll").remove();
    }
    catch(error)
    {
      
    }

  }

  componentDidMount() {
    this.unmountScript();
    window.scrollTo(0, 1);
    this.fetchApi();
    this.fetchJScript();

  }

  componentDidUpdate = (prevProps) => {
    this.fetchScroll();
    window.scrollTo(0, 1);
  };

  componentWillUnmount()
  {
    this.unmountScript();
  }

  
  
  render(){
    
    if(this.state.redirect!==""){
      let redirectUrl = this.state.redirect;
      /*
      this.setState({
        redirect: ''
      })
      */
      return <Redirect to={redirectUrl} />
    }
    
    if(this.state.product.length===0)
    {
      return <LoadingProduct />
    }

    return (
<section id="booking" style={{ backgroundColor: "#ffffff" }}>



{this.state.product.map((product,index) => {


    let difficultyLevel = ('');
    if(product.difficultyLevel!==null)
    {
        difficultyLevel = (
                      <span><i className="fas fa-signal text-danger"></i> <b>{product.difficultyLevel}</b> &nbsp;&nbsp;</span>
                        )
    }

    let privateActivity = ('');
    if(product.privateActivity)
    {
        privateActivity = (
                      <span className="badge badge-danger">PRIVATE TOUR</span>
                        )
    }

    let guidanceTypes = ('');
    if(product.guidanceTypes.length!==0)
    {
        let guidanceLang = ('');
        product.guidanceTypes.forEach((guidanceType, index) => {
          guidanceLang = guidanceLang + '<span class="badge badge-secondary">'+ guidanceType.language +'</span>&nbsp;';
        })
        guidanceTypes = (
            <div >
            <i className="fas fa-info-circle text-secondary mb-4" style={{ width: "20px" }}></i>
             Live Tour Guide in <span dangerouslySetInnerHTML={ {__html: guidanceLang} } />
             </div> 
          )
    }

    let startPointsTab = ('');
    let startPoints = ('');
    if(product.startPoints.length!==0)
    {
        startPointsTab = (
          <li className="nav-item">
            <a className="nav-link text-theme" id="meeting-tab" data-toggle="tab" href="#meeting-content" role="tab" aria-controls="meeting" aria-selected="false"><b>Meeting point</b></a>
          </li>
                        )
        
        product.startPoints.forEach((startPoint,index) => {
        
        let latitude = startPoint.latitude;
        let longitude = startPoint.longitude;
        let googleIframe = '<iframe loading="lazy" src="https://maps.google.com/maps?q='+latitude+','+longitude+'&hl=en;z=13&amp;output=embed" width="600" height="450" frameborder="0" ></iframe>';
        startPoints = (
          <div key={index} className="tab-pane fade mt-4" id="meeting-content" role="tabpanel" aria-labelledby="meeting-tab">
              You can start this experience at the following places:
              <div>
                <h3 className="mt-4 mb-0">{startPoint.title}</h3>
               {startPoint.addressLine1} {startPoint.addressLine2} {startPoint.addressLine3} {startPoint.city} {startPoint.state} {startPoint.postalCode} {startPoint.countryCode}
              </div>
              <div className="map-responsive mt-2">
                  <div dangerouslySetInnerHTML={ {__html: googleIframe} } />
              </div>
          </div>
          )

        })
    }

    let agendaItemsTab = ('');
    let agendaItems = ('');
    if(product.agendaItems.length!==0)
    {
        agendaItemsTab = (
          <li className="nav-item">
            <a className="nav-link text-theme" id="agenda-tab" data-toggle="tab" href="#agenda-content" role="tab" aria-controls="agenda" aria-selected="false"><b>Itinerary</b></a>
          </li>
                        )

        agendaItems = (
          <div className="tab-pane fade mt-4" id="agenda-content" role="tabpanel" aria-labelledby="agenda-tab">
               {product.agendaItems.map((agendaItem) => {
                  return (
                    <div className="mb-4">
                      <h3>{agendaItem.title}</h3>
                      <div dangerouslySetInnerHTML={ {__html: agendaItem.body} } />
                    </div>
                    ) 
                })}
          </div>
          )
    }

    let pickupPlacesTab = ('');
    let pickupPlaces = ('');
    if(product.pickupPlaces.length!==0)
    {
        pickupPlacesTab = (
          <li className="nav-item">
            <a className="nav-link text-theme" id="pickup-tab" data-toggle="tab" href="#pickup-content" role="tab" aria-controls="pickup" aria-selected="false"><b>Pick-up</b></a>
          </li>
                        )

        pickupPlaces = (

          <div className="tab-pane fade mt-4" id="pickup-content" role="tabpanel" aria-labelledby="pickup-tab">
            We offer pick-up to the following places for this experience:
            <br /><br />
            <div>
                    <ul>
                      {product.pickupPlaces.map((pickupPlace) => {
                        return (<li>{pickupPlace.title}</li>)
                      })}
                    </ul>
            </div>
          </div>

          )
    }

    let included = ('');
    if(product.included!==null)
    {
        included = (
                    <div>
                      <h3 className="mt-4">What's included?</h3>
                      <div dangerouslySetInnerHTML={ {__html: product.included} } />
                 
                  </div>
                        )
    }

    let excluded = ('');
    if(product.excluded!==null)
    {
        excluded = (
                      <div>
                      <h3 className="mt-4">Exclusions</h3>
                       <div dangerouslySetInnerHTML={ {__html: product.excluded} } />
                       </div>
                        )
    }

    let requirements = ('');
    if(product.requirements!==null)
    {
        requirements = (
                      <div>
                      <h3 className="mt-4">What do I need to bring?</h3>
                       <div dangerouslySetInnerHTML={ {__html: product.requirements} } />
                       </div>
                        )
    }

    let attention = ('');
    if(product.attention!==null)
    {
        attention = (
                      <div>
                      <h3 className="mt-4">Please note</h3>
                       <div dangerouslySetInnerHTML={ {__html: product.attention} } />
                       </div>
                        )
    }



    return(

<div className="container" key={index}>
  <HelmetProvider>
  <Helmet>
        <script type="application/ld+json">{this.state.json_ld}</script>
        <title>{product.name}</title>
        {(() => {
          if(product.excerpt!==null) {
            return (
                <meta name="description" content={ product.excerpt.replace(/<[^>]+>/g, '') } />
              )
          }
        })()}


        <meta property="og:type" content="website"/>
        <meta property="og:title" content={product.name} />
        {(() => {
          if(product.excerpt!==null) {
            return (
                <meta name="og:description" content={ product.excerpt.replace(/<[^>]+>/g, '') } />
              )
          }
        })()}

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={product.name}/>
        {(() => {
          if(product.excerpt!==null) {
            return (
                <meta name="twitter:description" content={ product.excerpt.replace(/<[^>]+>/g, '') } />
              )
          }
        })()}
        
  </Helmet>
  </HelmetProvider>
  
  <div className="row">
      <div className="col-lg-7 mx-auto">
      <div style={{ height: "66px" }}></div>
      
  
      <div dangerouslySetInnerHTML={ {__html: product.images} } />
      
  
      <h1 className="mt-4">{product.name}</h1>
      
      <a href="#bookingCard" className="btn btn-theme btn-lg btn-block button-book-mobile js-scroll-trigger">Book now</a>
      
      <div className="text-muted mt-4 mb-4">
        <div dangerouslySetInnerHTML={ {__html: product.rating} } />
      </div>

      <div className="text-muted mt-4 mb-4">
          <i className="far fa-clock text-danger"></i> <b>{product.durationText}</b> &nbsp;&nbsp;
          {difficultyLevel}
          {privateActivity}
      </div>
      
      <div className="text-muted mt-4 mb-4">
          <div dangerouslySetInnerHTML={ {__html: product.alert} } />
      </div>
      
      <div className="text-muted mt-4 mb-4">
          <div dangerouslySetInnerHTML={ {__html: product.excerpt} } />
      </div>
      <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active text-theme" id="description-tab" data-toggle="tab" href="#description-content" role="tab" aria-controls="description" aria-selected="true"><b>Description</b></a>
          </li>
          {agendaItemsTab}
          {startPointsTab}
          {pickupPlacesTab}
      </ul>
      <div className="tab-content">
          <div className="tab-pane fade show active mt-4" id="description-content" role="tabpanel" aria-labelledby="description-tab">
            <div>
              <div dangerouslySetInnerHTML={ {__html: product.description} } />
            </div>
            <div>
                {included}
            </div>
            <div>
                {excluded}
            </div>
            <div>
                {requirements}
            </div>
            <div>
                {attention}
            </div>
        </div>
        
        {agendaItems}
        {startPoints}
        {pickupPlaces}

       

      </div>
      </div>
        
      <div className="col-lg-5">
      <div style={{ height: "64px" }}></div>
      <div className="card mb-4 shadow p-2">
                          
          <div className="card-body">
            <h3>{product.productCategory} Details</h3>              
            <br />
            <i className="far fa-clock text-secondary mb-4" style={{ width: "20px" }}></i> Duration: {product.durationText}
            <br />        
            <i className="fas fa-signal text-secondary mb-4" style={{ width: "20px" }}></i> Difficulty {product.difficultyLevel}
            <br />
            {guidanceTypes}
            

            {(() => {
                     if(product.cancellationPolicy !== undefined)
                     {
                            
                            return (
                                
                                  <div dangerouslySetInnerHTML={ {__html: product.cancellationPolicy} } />
                                
                              );
                     } 
            })()}

          </div>

    </div>

        <div id="bookingCard" className="card mb-4 shadow p-2">
            
        
        {(() => {
            if(product.bookingType === "custom")
            {        
                return (
                  <div dangerouslySetInnerHTML={ {__html: product.bookingCustom} } />
                 );
            }
            else
            {
                return (
                  <div>
                  <div className="card-header">
                    <h3><i className="fa-solid fa-ticket"></i>  Book {product.name}</h3>
                    Secure booking — only takes 2 minutes!
                  </div>
                  <BookingCalendar />
                  </div>
                  );
            }
        })()}

        
     
        </div>
    

    </div>

  <div className="clearfix"></div>
    
  </div>
  <div style={{ height: "40px", backgroundColor: "#ffffff" }}></div>
</div>

      )
})}
</section>
    	)
  }
}

export default Product;
