import React, { Component } from 'react';


class BookingCalendar extends Component {
  render(){
    return(
      <div id="BookingCalendar">
        <div id="bookingframe" className="card-body" style={{ paddingLeft: "1px", paddingRight: "1px", paddingTop: "20px", paddingBottom: "15px" }}>
          <div className="widget-body" id="WidgetContent">
            <div className="widget">
              <div id="proses">
                <div id="placeholder">
                  <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-2 big"></div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-2 big"></div>
                            </div>
                            <div className="ph-picture"></div>
                            <div className="ph-row">
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-4"></div>
                            </div>
                        </div>
                        
                        
                  </div>
                  <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-6 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                        </div>
                  </div>
                  <div className="ph-item">
                        
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-6 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                        </div>
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-4 big"></div>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
          <div id="ActivityBookingWidget">
          
          </div>
        </div>

          <div id="generic-loading-template" style={{ display: "none" }}>
            <div className="well well-large well-transparent lead">
              <i className="fa fa-spinner icon-spin icon-2x pull-left"></i> processing...
            </div>
          </div>
      </div>
      </div>
      </div>
    )
  }
}

export default BookingCalendar;