import React, { Component } from 'react';


class LoadingReviews extends Component {



  render(){
    
    return(
<div id="loadingReviews">
  <div className="bd-callout bd-callout-theme shadow-sm rounded" style={{
    marginTop: '15px',
    marginBottom: '15px'
   }} >
    

   <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>

  </div>
  <div className="bd-callout bd-callout-theme shadow-sm rounded" style={{
    marginTop: '15px',
    marginBottom: '15px'
   }} >
    

   <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>

  </div>
  <div className="bd-callout bd-callout-theme shadow-sm rounded" style={{
    marginTop: '15px',
    marginBottom: '15px'
   }} >
    

   <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>

  </div>
  <div className="bd-callout bd-callout-theme shadow-sm rounded" style={{
    marginTop: '15px',
    marginBottom: '15px'
   }} >
    

   <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>
    
  </div>
  <div className="bd-callout bd-callout-theme shadow-sm rounded" style={{
    marginTop: '15px',
    marginBottom: '15px'
   }} >
    

   <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>
    
  </div>
</div>

      )
  }
}

export default LoadingReviews;