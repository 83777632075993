import React, { Component } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";

class LoadingPage extends Component {



	render(){
    
		return(
<section id="page" style={{ backgroundColor: "#ffffff" }}>
<HelmetProvider>
    <Helmet>
        <title>Page</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Page" />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Page"/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
      </Helmet>
    </HelmetProvider>

  <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 mx-auto">
            <div className="row" style={{ paddingBottom: "0px" }}>
              <div className="col-lg-12 text-left">
                <div style={{ height: "77px" }}></div>
                <div className="card mb-8 shadow p-2">
                  <div className="card-body" style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "15px" }}>
                    <div className="text-right">
          
                    </div>
              
                    <div className="row" style={{ paddingBottom: "0px" }}>
                      <div className="col-lg-12 text-center pt-4">
                        

                        
                          <div className="ph-item border-0">
                            <div className="ph-col-12">
                              <div className="ph-row">
                                <div className="ph-col-4 empty"></div>
                                <div className="ph-col-4 big"></div>
                                <div className="ph-col-4 empty"></div>
                              </div>

                            </div>
                            <hr className="hr-theme" />
                          </div>
                        
                        
                      </div>
                    </div>
          
                    
                        
                          <div className="col-lg-8 mx-auto">
                          
                    <div className="ph-item justify-content-center border-0 pl-0 pr-0 pb-0 pt-2">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12 empty"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12 empty"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>

                          </div>
                          
                          <div style={{ height: "50px" }}></div>
                        
                    </div>

                  </div>
                  <div style={{ height: "40px" }}></div>    
                </div>
              </div>
            </div>
          </div>
        </div>

</section>
			)
	}
}

export default LoadingPage;