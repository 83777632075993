import React, { Component } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";

class LoadingCheckout extends Component {



	render(){
    
		return(
<section id="booking" style={{ backgroundColor: "#ffffff" }}>
      <div className="container">
      <HelmetProvider>
    <Helmet>
        <title>Shopping Cart</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Shopping Cart" />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Shopping Cart"/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
      </Helmet>
    </HelmetProvider>
          <div className="row">
            <div className="col-lg-12 col-md-12 mx-auto">
              <div className="row" style={{ paddingBottom: "0px" }}>
                <div className="col-lg-12 text-left">
                  <div style={{ height: "56px" }}></div>
                    <div className="row mb-2">  
                      <div className="col-lg-6 col-lg-auto mb-6 mt-4">
                            <div className="card shadow">
                              <div className="card-header bg-dark text-white pb-1">
                                <h4><i className="fas fa-shopping-cart"></i> Order Summary</h4>
                              </div>
                
                            <div className="card-body">

                              <div className="ph-item border-0 pl-0 pr-0">
                              <div className="ph-col-12">
                              
                              <div className="ph-row">
                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-12 empty"></div>

                                <div className="ph-col-8"></div>
                                <div className="ph-col-4 empty"></div>

                                <div className="ph-col-8"></div>
                                <div className="ph-col-4 empty"></div>

                                <div className="ph-col-8"></div>
                                <div className="ph-col-4 empty"></div>

                                <div className="ph-col-8"></div>
                                <div className="ph-col-4 empty"></div>

                                <div className="ph-col-12 empty"></div>                                
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-4 empty big"></div>
                                <div className="ph-col-2 big"></div>
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-4 empty big"></div>
                                <div className="ph-col-2 big"></div>
                              </div>
                            </div>
                            </div>

                            </div>
                            </div>
                      </div>
                      <div className="col-lg-6 col-lg-auto mb-6 mt-4">
                            <div className="ph-item pl-0 pr-0">
                              <div className="ph-col-12">
                             
                              <div className="ph-row">
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-4 empty big"></div>
                                <div className="ph-col-2 empty"></div>

                                <div className="ph-col-4 big"></div>
                                <div className="ph-col-6 empty big"></div>
                                <div className="ph-col-2 empty"></div>

                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-12 empty"></div>

                                <div className="ph-col-4 big"></div>
                                <div className="ph-col-6 empty big"></div>
                                <div className="ph-col-2 empty"></div>

                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-12 empty"></div>

                                <div className="ph-col-4 big"></div>
                                <div className="ph-col-6 empty big"></div>
                                <div className="ph-col-2 empty"></div>

                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-12 empty"></div>



                              </div>
                            </div>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </section>
			)
	}
}

export default LoadingCheckout;