import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Navbar, Nav }  from "react-bootstrap";

class MenuBottom extends Component {

  componentDidMount() {
    window.scrollTo(0, 1);
  }

  

  render(){
    

    return (

<Navbar id="MenuBottom" expand="lg" className="navbar navbar-expand d-md-none d-lg-none d-xl-none fixed-bottom shadow" 
style={{ 
  borderTopRightRadius: 20,
  borderTopLeftRadius: 20,
  backgroundColor: "#c53c46"
  }}>
<Nav className="navbar-nav nav-justified w-100 mb-2 mt-2 rounded">
  
  <NavLink as={Link} style={{ color : '#f2f2f2'}} activeStyle={{
    color: "#c53c46",
    backgroundColor: "#f2f2f2"
  }} activeClassName="active" className="nav-link font-weight-bold rounded" to={{ pathname: "/tours" }}>
    <i className="fas fa-atlas  fa-2x fa-fw"></i>
    <span className="small d-block">Tours</span>
  </NavLink>
  
  <NavLink as={Link} style={{ color : '#f2f2f2'}} activeStyle={{
    color: "#c53c46",
    backgroundColor: "#f2f2f2"
  }} activeClassName="active" className="nav-link font-weight-bold rounded" to={{ pathname: "/booking/checkout" }}>
    <i className="fas fa-shopping-cart  fa-2x fa-fw"></i>
    <span className="small d-block">Cart</span>
  </NavLink>

  <NavLink as={Link} style={{ color : '#f2f2f2'}} activeStyle={{
    color: "#c53c46",
    backgroundColor: "#f2f2f2"
  }} activeClassName="active" className="nav-link font-weight-bold rounded" to={{ pathname: "/booking/lastorder" }}>
    <i className="fas fa-file-signature fa-2x fa-fw"></i>
    <span className="small d-block">My Order</span>
  </NavLink>

  <NavLink as={Link} style={{ color : '#f2f2f2'}} activeStyle={{
    color: "#c53c46",
    backgroundColor: "#f2f2f2"
  }} activeClassName="active" className="nav-link font-weight-bold rounded" target="_blank"  to={{ pathname: "https://wa.me/"+ localStorage.getItem('footerWhatsapp') }}>
    <i className="fab fa-whatsapp  fa-2x fa-fw"></i>
    <span className="small d-block">WhatsApp</span>
  </NavLink>

 
  
</Nav>
</Navbar>

            )
  }
}

export default MenuBottom;
